<template>
	<div class="basket">
		<div class="goods">
			<div class="list">
				<div class="blockTitle">{{ $t("address.address") }}</div>
				<template v-for="(val, key) in address.list">
					<template v-if="val.edit">
						<AddressFrom :detail="detail" :isEdit="isEdit" @done="getAddress" @cancel="cancel">
						</AddressFrom>
					</template>
					<template v-else>
						<div class="itemBody address_item">
							<div class="info">{{ val.man }}</div>
							<div class="info">{{ val.phone }}</div>
							<div class="info">{{ val.area }} - {{ val.address }}</div>
							<div v-if="val.addressId != address.active" @click="address.active = val.addressId"
								class="choose">
								{{ $t("post.choose") }}
							</div>
							<div class="edit" @click="
								() => {
									cancel();
									address.list[key].edit = true;
									detail = val;
									isEdit = true;
								}
							">
								{{ $t("address.edit") }}
							</div>
						</div>
					</template>
				</template>
				<!-- <div class="blockTitle">配送方式</div>
				<div v-for="val in 2" class="itemBody mail_item">
					<div :class="`check ${ischek?'checked':''}`"></div>
					<span class="name">顺丰速运  订单经确认后，预计48小时内发货（预售商品除外）</span>
				</div> -->
				<div class="blockTitle">{{ $t("tip.pmethod") }}</div>
				<div class="itemBody pay_methods">
					<div class="item" v-for="val in paymethod.list" @click="paymethod.active = val.value">
						<div :class="`check ${paymethod.active == val.value ? 'checked' : ''}`"></div>
						<span>{{ val.name }}</span>
					</div>
				</div>
				<div><el-checkbox v-model="checked_xieyi" style="margin-top: 50px;">Estoy de acuerdo com los
						<b><a @click="$router.push('/article?id=16')" target="_blank">términos del
								servicio</a></b> y los
						acepto sin reservas.
					</el-checkbox>
				</div>
			</div>
			<div class="total">
				<div class="block">
					<div class="count">{{ $t("cart.name") }}</div>
					<div class="line"></div>
					<div v-for="val in goods" class="goodsItem">
						<div class="pic">
							<img class="regular" :src="val.cover" />
						</div>
						<div class="info">
							<div class="name">{{ val.spuName }}</div>
							<div class="price">€{{ val.price }}</div>
							<div v-for="v in val.specInfo.split(',')" class="info-item">{{ v }}</div>
							<div class="info-item">{{ $t("cart.num") }}：{{ val.num }}</div>
						</div>
					</div>
				</div>
				<div class="totalPrice block">
					<div class="count">{{ $t("tip.selectItem") + goods.length }}</div>
					<div class="line"></div>
					<div class="item">
						<span>{{ $t("cart.subtotal") }}</span>
						<span>€{{ Number(totalAmount).toFixed(2) }}</span>
					</div>
					<div class="item">
						<span>{{ $t("cart.coupon") }}</span>
						<span>-€{{ Number(totalRebate).toFixed(2) }}</span>
					</div>
					<div class="item">
						<span>{{ $t("tip.postFee") }}</span>
						<span>{{ $t("tip.free") }}</span>
					</div>
					<div class="line" style="margin-top: 20px"></div>
					<div class="item">
						<span>{{ $t("cart.grandTotal") }}</span>
						<span>€{{ Number(totalAmount).toFixed(2) }}</span>
					</div>
					<div class="submit" @click="submit">{{ $t("tip.submit") }}</div>
					<form name="from" :action="redsysParams.form_action" method="POST" id="myForm">
						<input type="hidden" name="Ds_SignatureVersion" :value="redsysParams.Ds_SignatureVersion" />
						<input type="hidden" name="Ds_MerchantParameters" :value="redsysParams.Ds_MerchantParameters" />
						<input type="hidden" name="Ds_Signature" :value="redsysParams.Ds_Signature" />
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Api from "../../api/request.js";
import AddressFrom from "../Address/form";
export default {
	name: "Ensure",
	components: {
		AddressFrom,
	},
	data() {
		return {
			checked_xieyi: false,
			address: {
				list: [],
				active: "",
			},
			paymethod: {
				list: [{
					name: this.$t("checkout.online"),
					value: 1
				},
					// {
					// 	name: this.$t("checkout.cash"),
					// 	value: 2
					// },
				],
				active: "",
			},
			goods: [],
			carts: [],
			totalAmount: 0,
			totalRebate: 0,
			redsysParams: {},
			isEdit: false,
			detail: {},
		};
	},
	created() {
		this.getAddress();
		this.getGoodsList();
	},
	methods: {
		getAddress() {
			Api.Address.list({}).then((res) => {
				if (res.data.data) {
					res.data.data.map((item) => {
						item.edit = false;
					});
				}
				this.address.list = res.data.data || [];
				var defAddress = res.data.data.find((item) => {
					return item.defaultFlag == "1";
				});
				if (defAddress) {
					this.address.active = defAddress.addressId;
				} else {
					this.address.active = res.data.data[0].addressId;
				}
			});
		},
		getGoodsList() {
			this.goods = JSON.parse(sessionStorage.getItem("cart"));
			var total = 0;
			var totalRebate = 0;
			var carts = [];
			for (var i in this.goods) {
				carts.push(this.goods[i].cartId);
				total += parseFloat(this.goods[i].price) * parseInt(this.goods[i].num);
				totalRebate += parseFloat(this.goods[i].rebate) * parseInt(this.goods[i].num);
			}
			this.carts = carts;
			this.totalAmount = total;
			this.totalRebate = totalRebate;
		},
		submit() {
			if (!this.checked_xieyi) {
				this.$message({
					type: 'error',
					message: `Estoy de acuerdo com los términos del servicio y los acepto sin reservas.`
				});
				return false;
			}
			this.$dialog.loading();
			var param = {
				addressId: this.address.active,
				carts: JSON.stringify(this.carts),
			};
			Api.Order.ensure(param).then((res) => {
				if (res.data.status == "SUCCESSS") {
					Api.Order.create(param).then((res) => {
						this.$dialog.close();
						if (res.data.status == "SUCCESSS") {
							if (this.paymethod.active == 1) {
								this.handlePay(res.data.data.orderId);
							} else { }
							// this.$router.replace('/OrderList');
						}
					});
					this.$bus.$emit("getCartNum");
				} else {
					this.$dialog.close();
				}
			});
		},
		cancel() {
			this.address.list.map((item, key) => {
				if (!item.addressId) {
					this.address.list.splice(key, 1);
				}
				item.edit = false;
			});
		},
		handlePay(id) {


			Api.Order.readyToPay({
				orderId: id,
				paymentMethod: "redsys",
			}).then((res) => {
				if (res.data.status == "SUCCESSS") {
					this.redsysParams = res.data.data.redsys;
					setTimeout(() => {
						document.getElementById("myForm").submit();
					}, 1000);
				}
			});
		},
	},
};
</script>

<style lang="less" scoped>
.goods {
	width: 1300px;
	margin: 20px auto 213px;
	display: flex;

	.list {
		flex: 1;

		.blockTitle {
			height: 65px;
			display: flex;
			align-items: center;
			border-bottom: solid 1px #757575;
			font-size: 18px;
			font-weight: bold;
		}

		.itemBody {
			padding: 20px;
			border: 1px solid #d9d9d9;
			margin-top: 30px;

			.check {
				width: 9px;
				height: 9px;
				background: #ffffff;
				border: 1px solid #000000;
				border-radius: 50%;
				margin-right: 12px;
				transition: all 0.3s;

				&.checked {
					background: radial-gradient(#000 50%, #fff 51%);
				}
			}
		}

		.address_item {
			margin-top: 20px;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100px;

			.info {
				font-size: 14px;
			}

			.choose {
				position: absolute;
				right: -1px;
				top: -1px;
				width: 102px;
				height: 34px;
				background: #123178;
				border-radius: 0px 0px 0px 6px;
				text-align: center;
				line-height: 34px;
				font-size: 14px;
				font-weight: 400;
				color: #ffffff;
			}

			.edit {
				position: absolute;
				right: 20px;
				bottom: 20px;
				width: 120px;
				height: 40px;
				line-height: 40px;
				border-radius: 6px;
				text-align: center;
				border: 1px solid #123178;
				font-size: 14px;
				font-weight: 600;
				color: #123178;
				cursor: pointer;

				&:hover {
					background-color: #efefef;
				}
			}
		}

		.mail_item {
			display: flex;
			align-items: center;
			cursor: pointer;

			.name {
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
			}
		}

		.pay_methods {
			display: flex;

			.item {
				display: flex;
				align-items: center;
				margin-right: 40px;
				cursor: pointer;

				&>img {
					height: 20px;
					margin-right: 12px;
				}

				&>span {
					font-size: 14px;
				}
			}
		}
	}

	.total {
		width: 400px;
		margin-left: 30px;
		padding-top: 65px;

		.block {
			padding: 0 20px 20px;
			background: #f2f2f4;

			.goodsItem {
				margin-top: 20px;
				display: flex;

				.pic {
					width: 110px;
					height: 140px;
					background: #d8d8d8;
					margin-right: 20px;
				}

				.info {
					flex: 1;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					font-size: 14px;
					line-height: 14px;

					.name {
						font-weight: bold;
						height: 13px;
						color: #333333;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						overflow: hidden;
					}

					.info-item {
						color: #999999;
						font-size: 12px;
						line-height: 12px;
					}
				}
			}

			&.totalPrice {
				background: #123178;
				color: #fff;

				.line {
					background: #fff;
				}

				.submit {
					height: 48px;
					background: #ffffff;
					border-radius: 6px;
					font-size: 16px;
					font-weight: 600;
					color: #123178;
					line-height: 48px;
					text-align: center;
					margin-top: 20px;
					cursor: pointer;

					&:hover {
						background-color: #efefef;
					}
				}
			}

			.count {
				height: 60px;
				line-height: 60px;
				font-size: 14px;
				font-weight: 400;
				text-align: center;
			}

			.line {
				height: 1px;
				background: #000;
			}

			&>.item {
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				margin-top: 20px;
				display: flex;
				justify-content: space-between;
			}

			.ways {
				display: flex;
				margin-top: 20px;

				.item {
					display: flex;
					align-items: center;
					margin-right: 40px;

					&>img {
						display: block;
						height: 20px;
						margin-right: 12px;
					}

					&>span {
						font-size: 14px;
						font-weight: 400;
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.title {
		font-size: 4vw;
		font-weight: 600;
		line-height: 5vw;
		margin: 3vw 0;
		text-align: center;
	}

	.goods {
		width: 94vw !important;
		padding: 0 3vw;
		display: flex;
		flex-direction: column;
		margin-bottom: 30px;

		.list .item .main .img {
			width: 140px;
			height: auto;
		}

		.list .item .main .info .bottom {
			position: relative;
			justify-content: flex-start;
		}

		.total {
			width: auto;
			margin-left: 0px;
		}

		.list .item .main {
			display: flex;
			flex-direction: column;
		}
	}
}
</style>
